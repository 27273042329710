<template>
  <main class="mainlayout">
 
    <Sidebar/> 

    <div class="content">   
      <div class="newdevice" v-show="!showMulti"> 
        <button class="insead autowidth large left white" :disabled="loading" @click="showMulti = true">Add multiple</button>   
        <button class="insead autowidth large left" :disabled="addBtnDisabled" :class="{disabled: addBtnDisabled}" @click="addDevice">Add single device</button>   
        <input v-model.trim="newDevice.id" type="text" :disabled="loading" autocomplete="off" placeholder="Serial number (Id)" /> 
        <input v-model.trim="newDevice.name" type="text" :disabled="loading" autocomplete="off" placeholder="Name" /> 
        <select class="select-css" v-model="newDevice.organizationId" :disabled="loading" v-if="$store.getters.isAdmin">
          <option value=undefined selected>No organization</option>
          <option v-for="org in organizations" v-bind:value="org.id" :key="org.id">
            {{org.name}}
          </option>
        </select>        
        <button class="insead autowidth right" :disabled="loading" v-if="$store.getters.isAdmin" @click="syncWithMdm">
          <img v-if="syncing" style="height: 20px; vertical-align: middle; margin-right: 5px;" src="/spinner-white.svg">
          {{(syncing ? 'Sync in progress' : 'Sync all with MDM in:')}}
        </button>
        <select v-model="mdmAccount" :disabled="loading" v-if="$store.getters.isAdmin">
          <option>Avris</option>
          <option>Insead</option>
          <option>InseadEvents</option>
        </select>
      </div>

      <div v-show="showMulti" class="newdevices">
        <button class="insead autowidth large white" @click="showMulti = false">Cancel</button>
        <button 
          :disabled="!newDevicesList || newDevicesList.length == 0 || loading" 
          class="insead autowidth left large"
          :class="{disabled: (!newDevicesList || newDevicesList.length == 0)}"
          @click="addMultipleDevices"
        >
          Add {{newDevicesList ? newDevicesList.length : 0}} devices
        </button>
        <textarea v-model="newDevicesCsv" placeholder="Paste CSV content here. Header row required with 'Id', 'Name' and 'Organization' columns."></textarea>
        <div v-if="newDevicesCsv">          
          Found <b>{{newDevicesList ? newDevicesList.length : 0}}</b> valid devices:
          <br>
          <span v-for="device in newDevicesList" :key="device.id">
            {{device.id}} - {{device.name}} - {{device.organization ? device.organization.name : 'No Org.'}}
          </span>
        </div>
        <div class="formerror" v-if="error">{{error}}</div>
      </div>

      <br>  
      <br>  
      <input v-model.trim="searchTerm" type="text" :disabled="loading" autocomplete="off" placeholder="Search..." /> 
      <br>
      <br>      
      <VueGoodTable
      ref="datatable"
      :columns="columns"
      :rows="devices"
      striped
      styleClass="vgt-table striped"
      :search-options="{
        enabled: false,
        externalQuery: searchTerm
      }"
      :select-options="{ 
        enabled: true,
        selectOnCheckboxOnly: true 
      }">
        <template v-slot:selected-row-actions>  
          <button class="insead autowidth right" :disabled="loading || !selectedDevices.length" @click="showDeleteModal = true">Delete</button>              
        </template>
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'actions'" class="rowActions">
            <button class="insead autowidth right" @click="editRow(props.row.id)">Edit</button>
          </span>          
          <span v-else>
            {{props.formattedRow[props.column.field]}}
          </span>
        </template>
      </VueGoodTable>

      <span class="spinner relative" v-if="loading" />
    </div>

    <Modal v-if="showModal" @close="showModal = false; error = ''" class="devicemodal">      
      <h3 slot="header">Edit device</h3>
      <div slot="body">
        <label>Serial number (Id)</label>
        <input v-model.trim="tempDevice.id" type="text" placeholder="serial number (Id)"> 
        <label>Name</label>
        <input v-model.trim="tempDevice.name" type="text" placeholder="custom device name"> 
        <label v-if="$store.getters.isAdmin">Organization</label>
        <select v-if="$store.getters.isAdmin" class="select-css" v-model="tempDevice.organizationId">
          <option :value=null selected>None</option>
          <option v-for="org in organizations" v-bind:value="org.id" :key="org.id">
            {{org.name}}
          </option>
        </select>

        <div class="formerror" v-if="error">{{error}}</div> 
        
        <div v-if="this.tempDevice.userId">
          <br>
          <br>
          There is a user currently linked to this device, if you update device serial number, the user will need to login again.
        </div>
      </div>
      <div slot="footer">
        <button class="insead" :class="{disabled: updateBtnDisabled}" @click="updateDevice" :disabled="updateBtnDisabled">Update</button>        
      </div>
    </Modal>    

    <Modal v-if="showDeleteModal" @close="showDeleteModal = loading; error = '';" class="deletedevicemodal">      
      <h3 slot="header">Delete devices</h3>
      <div slot="body">   
        {{selectedDevices.length}} selected devices will be removed together with their associated technical (guest mode) user. 
        Any data (results, recordings) will be kept for potential future use, but the info from which user the data originated from will be lost. 
        These data sets will not be available from the dashboard UI.
        <h5 v-if="deviceDeleteCount && loading">Deleting device {{deviceDeleteCount}}/{{selectedDevices.length}}</h5>
        <h5 v-if="deviceDeleteCount == selectedDevices.length && !loading">Devices deleted successfully</h5>
        <div class="formerror" v-if="error">{{error}}</div>                 
      </div>
      <div slot="footer">
        <button v-if="!this.deviceDeleteCount" class="insead" @click="showDeleteModal = false; error = '';" :disabled="loading">Go back</button> 
        <button v-if="!this.deviceDeleteCount" class="insead red" @click="removeDevices" :disabled="loading">Delete devices</button>       
        <span class="spinner relative" v-if="loading" />   
      </div>
    </Modal>  
 
  </main>
</template>

<script>
import axios from 'axios';
import Papa from 'papaparse';

import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table';
import Modal from '@/components/Modal.vue'
import Sidebar from '@/components/Sidebar.vue'


export default {
  name: 'Devices',
  data: function(){
    return {
      columns: [
        {
          label: 'Id',
          field: 'id',
        },
        {
          label: 'Name',
          field: 'name',
        },        
        {
          label: 'Last login',
          field: 'lastLogin',
        },
        {
          label: 'Created',
          field: 'created',
        }, 
        {
          label: 'User Id',
          field: 'userId',
        },
        {
          label: 'Organization',
          field: 'organization.name',
          filterOptions: {
            enabled: this.$store.getters.isAdmin, 
            placeholder: 'Filter by Org', 
          },
        },
        {
          label: 'Disabled',
          field: 'disabled',
        },
        {
          label: 'MDM Account',
          field: 'mdmAccount',
        },
        {
          label: 'MDM Id',
          field: 'mdmId',
        },
        {
          label: 'Actions',
          field: 'actions'
        },
      ],      
      showMulti: false,
      newDevicesCsv: '',
      devices: [],
      organizations: [],
      loading: false,
      syncing: false,
      mdmAccount: 'Avris',
      error: undefined,
      searchTerm: undefined,
      showModal: false,   
      showDeleteModal: false,   
      deviceDeleteCount: undefined,
      newDevice: {
        id: undefined,
        name: undefined
      },  
      tempDeviceIdBeforeEdit: undefined,
      tempDeviceNameBeforeEdit: undefined,
      tempDeviceOrganizationIdBeforeEdit: null,
      tempDevice: {
        id: undefined,
        name: undefined,
        organizationId: null
      }, 
    }
  },
  computed: {   
    addBtnDisabled(){
      return this.loading || !this.newDevice.id || !this.newDevice.name
    },
    updateBtnDisabled(){
      return this.loading || !this.tempDevice.id || !this.tempDevice.name || (this.tempDevice.id == this.tempDeviceIdBeforeEdit && this.tempDevice.name == this.tempDeviceNameBeforeEdit && this.tempDevice.organizationId == this.tempDeviceOrganizationIdBeforeEdit)
    },
    selectedDevices(){
      return this.$refs.datatable.selectedRows;
    },
    newDevicesList(){
      return Papa.parse(this.newDevicesCsv, {header: true, skipEmptyLines: true, transformHeader: function(h){
        return h?.toLowerCase().trim()
      }}).data
        .map(d => ({id: d.id?.trim(), name: d.name?.trim(), organization: this.organizations.find(o => o.name.toLowerCase() == d.organization?.toLowerCase().trim() ), organizationId: this.organizations.find(o => o.name.toLowerCase() == d.organization?.toLowerCase().trim())?.id }))
        .filter(d => d.id && d.name)
    },
  },
  methods: {  
    async syncWithMdm(){
      try{
        this.$nprogress.start()
        this.loading = true   
        this.syncing = true
        let resp = await axios.post(`devices/mdmsync?account=${this.mdmAccount}`)    
        this.devices = resp.data
      }
      catch(err){
        console.log("Error when syncing devices with MDM: " + err)
        this.error = err
      }
      finally{
        this.loading = false
        this.syncing = false
        this.$nprogress.done()
      }
    },
    async getOrganizations(){
      let resp = await axios({ url: "organizations" })
      this.organizations = resp.data

      // set filters
      let foundIndex = this.columns.findIndex((c) => {
        return c.field == 'organization.name'
      })        
      this.$set(this.columns[foundIndex].filterOptions, 'filterDropdownItems', this.organizations.map(o => o.name));
    },
    async removeDevices(){
      try{
        this.$nprogress.start()
        this.loading = true   
        this.deviceDeleteCount = 0
        //console.log('ANYAD')
        for (let index = 0; index < this.selectedDevices.length; index++) {
          const device = this.selectedDevices[index]      
          this.deviceDeleteCount++
          //console.log('deviceDeleteCount ' + this.deviceDeleteCount)
          await axios({ url: `devices/${device.id}`, method: "DELETE" })    
        }

        this.showDeleteModal = false
        this.getDevices()        
      }
      catch(err){
        console.log("Error when removing devices: " + err);
        this.error = err;
      }
      finally{
        this.loading = false;
        this.$nprogress.done();
        this.deviceDeleteCount = undefined
      }
    },
    async editRow(id){      
      this.tempDevice = JSON.parse(JSON.stringify(this.devices.find(s => s.id == id))) 
      this.tempDeviceIdBeforeEdit = id
      this.tempDeviceNameBeforeEdit = this.tempDevice.name
      this.tempDeviceOrganizationIdBeforeEdit = this.tempDevice.organizationId
      this.showModal = true
    }, 
    async getDevices(){
      try{
        this.$nprogress.start();
        this.loading = true;
        let resp = await axios({ url: "devices" }) 
        this.devices = resp.data;
      }
      finally{
        this.loading = false;
        this.$nprogress.done();
      }
    },  
    async addDevice(){
      try{
        this.error = undefined;
        this.$nprogress.start();
        this.loading = true;
        // eslint-disable-next-line
        let resp = await axios({ url: "devices", data: [this.newDevice], method: "POST" }); 
        resp.data.forEach(newDevice => {
          newDevice.organization = this.organizations.find(o => o.id == newDevice.organizationId)
          this.devices.push(newDevice)
        });        
        this.newDevice = {
          id: undefined,
          name: undefined
        }
      }
      catch(err){
        console.log("Error when adding device: " + err.response);
        this.error = err;
      }
      finally{
        this.loading = false;
        this.$nprogress.done();
      }
    },  
    async addMultipleDevices(){
      try{
        this.error = undefined;
        this.$nprogress.start();
        this.loading = true;
        // eslint-disable-next-line
        let resp = await axios({ url: "devices", data: this.newDevicesList, method: "POST" }); 
        resp.data.forEach(newDevice => {
          newDevice.organization = this.organizations.find(o => o.id == newDevice.organizationId)
          this.devices.push(newDevice)
        });        
        this.newDevicesCsv = ''
      }
      catch(err){
        console.log("Error when adding multiple devices: " + err.response);
        this.error = err;
      }
      finally{
        this.loading = false;
        this.$nprogress.done();
      }
    },  
    async updateDevice(){
      if(this.tempDevice.userId && this.tempDevice.id != this.tempDeviceIdBeforeEdit && !window.confirm('There is a user currently linked to this device, if you update device serial number, the user will need to login again. Are you sure?')){
        //this.showModal = false
        return
      }
      try{        
        this.error = undefined;
        this.$nprogress.start();
        this.loading = true;
        // eslint-disable-next-line
        let resp = await axios({ url: `devices/${this.tempDeviceIdBeforeEdit}`, data: this.tempDevice, method: "POST" }); 
        console.log(resp.data)      
        let tmp = this.devices.find(d => d.id == this.tempDeviceIdBeforeEdit)      
        tmp.id = resp.data.id
        tmp.name = resp.data.name    
        tmp.userId = resp.data.userId
        tmp.created = resp.data.created
        tmp.lastLogin = resp.data.lastLogin
        tmp.organizationId = resp.data.organizationId
        tmp.organization = this.organizations.find(o => o.id == resp.data.organizationId)
        this.showModal = false
      }
      catch(err){
        console.log("Error when updating device: " + err.response);
        this.error = err;
      }
      finally{
        this.loading = false;
        this.$nprogress.done();
      }
    }, 
  },    
  components: {
    VueGoodTable, 
    Modal,
    Sidebar
  },
  mounted() {
    this.getDevices()
    this.getOrganizations()
  },
  created() {
  }
}
</script>

<style lang="scss">
.newdevice{
  display: flex;

  input[type=text], select{
    // max-width: 25%;
    // float: left;
    flex-grow: 1;
    margin-left: 10px;
  }  

  button.insead{
    flex: none;
  }  
}

.deletedevicemodal{
  .modal-container{
    width: 600px;
  }
  .modal-footer{
    padding-top: 35px;

    > div{
      display: flex;
      justify-content: space-between;
    }

    button.insead{
      width: 270px;    
    }
  }
}

.newdevices{
  textarea{
    margin-top: 10px;
    max-width: 100%;
    min-width: 100%;
    min-height: 100px;
  }

  span{
    display: inline-block;
    padding: 5px;
    border-radius: 3px;
    margin-right: 5px;
    margin-top: 5px;
    background-color: $green2;
    color: white;
  }
}

.rowActions{
  display: flex;
  opacity: 0.0;
}

.vgt-table tr:hover .rowActions{
  opacity: 1;
}
</style>
